<template>
  <Layout>
    <h1 class="uk-heading-small" id="content">Customers</h1>
    <table class="uk-table uk-table-divider">
      <caption></caption>
      <thead>
        <tr>
          <th scope="col" class="w-20 p-3">Customer Number</th>
          <th scope="col" class="w-65 p-3">Customer Name</th>
          <th scope="col" class="w-15 p-3">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" v-for="customers in customers" :key="customers.name">
          <td>{{ customers.customernumber }}</td>
          <td>{{ customers.customername }}</td>
          <td><router-link :to="'/customers/' + customers.id">View</router-link></td>
          <!-- and so on -->
        </tr>
      </tbody>
    </table>

  </Layout>
</template>



<script>

import CustomerService from "../services/customer.service";
import EventBus from "../common/EventBus";
import Layout from '../layouts/private.vue'

export default {
  name: "Customers Overview",
  components: { Layout },
  data() {
    return {
      content: "Customers",
      customers: "",
    };
  },
  mounted() {
    CustomerService.getCustomers().then(
      (response) => {
        this.customers = response.data.results;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );
  },
};

</script>
