  
import api from './api';
class CustomerService {

  getCustomers() {
    return api.get('/customers');
  }
  getCustomer(id) {
    const customerconstuct = '/customers/'+id;
    return api.get(customerconstuct);
  }
  updateCustomer(id, customerBody) {
    const customerconstuct = '/customers/'+id;
    return api.patch(customerconstuct,customerBody);
  }

}

export default new CustomerService();
